import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import FormProperties from './LaceyActDetailsFormProperties'
import LaceyActDetailFieldsPlaceholder from './LaceyActDetailFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues } = FormProperties.formikProps

function LaceyActDetailsSection(props) {
  const { laceyActDetails = [] } = props

  const generatedIndex = useRef(0)
  const { getValues, setValue } = useFormContext()

  function handleAddLaceyActDetail(data) {
    const laceyActDetails = [...getValues('laceyActDetails'), data]

    setValue('laceyActDetails', laceyActDetails, { shouldDirty: true })
  }

  function handleDeleteLaceyActDetail(index) {
    const laceyActDetails = [...getValues('laceyActDetails')]

    laceyActDetails.splice(index, 1)

    setValue('laceyActDetails', laceyActDetails, { shouldDirty: true })
  }

  function handleEditLaceyActDetail(index, data) {
    const laceyActDetails = [...getValues('laceyActDetails')]

    laceyActDetails[index] = data

    setValue('laceyActDetails', laceyActDetails, { shouldDirty: true })
  }

  return (
    <Grid container spacing={2}>
      {_.isEmpty(laceyActDetails) ? (
        <Grid item xs={12}>
          <LaceyActDetailFieldsPlaceholder onAdd={handleAddLaceyActDetail} />
        </Grid>
      ) : (
        laceyActDetails.map((detail, index) => (
          <React.Fragment key={++generatedIndex.current}>
            <Grid item xs={12}>
              <LaceyActDetailEntry
                laceyActDetails={detail}
                onAdd={handleAddLaceyActDetail}
                onDelete={() => handleDeleteLaceyActDetail(index)}
                onEdit={(data) => handleEditLaceyActDetail(index, data)}
              />
            </Grid>
            {index + 1 !== laceyActDetails.length && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        ))
      )}
    </Grid>
  )
}

function LaceyActDetailEntry(props) {
  const { laceyActDetails, onAdd, onDelete, onEdit } = props

  const methods = useForm({ defaultValues: laceyActDetails })
  const {
    formState: { isDirty }
  } = methods

  function handleEditLaceyActDetail(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          {isDirty ? (
            <Grid container spacing={1}>
              <Grid item xs='auto'>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton
                      color='secondary'
                      onClick={() => methods.reset()}
                      size='medium'
                    >
                      Reset
                    </CngButton>
                  </Grid>
                  <Grid item xs='auto'>
                    <CngButton
                      color='primary'
                      onClick={methods.handleSubmit(handleEditLaceyActDetail)}
                      size='medium'
                    >
                      Save changes
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid justify='flex-end' container spacing={1}>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Add'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'plus']}
                      onClick={() => onAdd(initialValues)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Clone'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'copy']}
                      onClick={() => onAdd({ ...laceyActDetails, id: undefined })}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs='auto'>
                <Tooltip placement='bottom' title='Delete'>
                  <span>
                    <CngIconButton
                      icon={['fal', 'trash']}
                      onClick={() => onDelete(laceyActDetails)}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default LaceyActDetailsSection
