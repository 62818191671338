import { useTheme } from '@material-ui/core'
import React, { useContext, useRef, useState } from 'react'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import LineItemApiUrls from '../../../apiUrls/LineItemApiUrls'
import Table from '../../../components/aciacehighway/Table'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LineItemDialog from './LineItemDialog'
import { InvoiceLookupsContext } from '../lookups/InvoiceLookupsContext'
import FormProperties from './lineItem/FormProperties'
import _ from 'lodash'

const {
  filter: { EQUAL }
} = constants

function LineItemTable(props) {
  const { invoiceId, showNotification } = props

  const tableRef = useRef(null)
  const [lineItemDialog, setLineItemDialog] = useState({
    open: false,
    lineItem: null,
    isView: false
  })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    lineItem: null
  })
  const { getLookupValue } = useContext(InvoiceLookupsContext)
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { createRecord, deleteRecord, updateRecord } = useServices()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    const partNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_NO
    )
    const partDesc = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.PART_DESC
    )
    const htsCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.HTS_CODE
    )
    const qty = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.LineItem.QTY)
    const unitValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.UNIT_VALUE
    )
    const grossWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.GROSS_WT
    )
    const netWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LineItem.NET_WT
    )
    const cloneButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CLONE_BUTTON
    )
    const editButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.EDIT_BUTTON
    )
    const deleteButton = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DELETE_BUTTON
    )

    return {
      partNo,
      partDesc,
      htsCode,
      qty,
      unitValue,
      grossWeight,
      netWeight,
      cloneButton,
      editButton,
      deleteButton
    }
  }

  const columns = [
    {
      field: 'partNo',
      sortKey: 'partNo',
      title: translatedTextsObject.partNo
    },
    {
      field: 'partDesc',
      sortKey: 'partDesc',
      title: translatedTextsObject.partDesc
    },
    {
      field: 'htsCode',
      sortKey: 'htsCode',
      title: translatedTextsObject.htsCode
    },
    {
      field: 'qty',
      sortKey: 'qty',
      title: translatedTextsObject.qty
    },
    {
      field: 'unitValue',
      sortKey: 'unitValue',
      title: translatedTextsObject.unitValue
    },
    {
      field: 'grossWeight',
      sortKey: 'grossWeight',
      title: translatedTextsObject.grossWeight
    },
    {
      field: 'netWeight',
      sortKey: 'netWeight',
      title: translatedTextsObject.netWeight
    }
  ]

  function handleCreateLineItem(data) {
    createRecord.execute(
      LineItemApiUrls.POST,
      FormProperties.toServerDataFormat(data),
      (response) => {
        const { errorMessages } = response

        if (!_.isEmpty(errorMessages)) {
          errorMessages.forEach((message) => showNotification('error', message))
        } else {
          showNotification('success', 'Item information saved.')

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }

          setLineItemDialog({ open: false, lineItem: null, isView: false })
        }
      },
      (error) => console.error(error)
    )
  }

  function handleEditLineItem(data) {
    updateRecord.execute(
      LineItemApiUrls.PUT,
      FormProperties.toServerDataFormat(data),
      (response) => {
        const { errorMessages } = response

        if (!_.isEmpty(errorMessages)) {
          errorMessages.forEach((message) => showNotification('error', message))
        } else {
          showNotification('success', 'Item information saved.')

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }

          setLineItemDialog({ open: false, lineItem: null, isView: false })
        }
        if (tableRef.current?.performRefresh) {
          tableRef.current.performRefresh()
        }
      },
      (error) => console.error(error)
    )
  }

  function handleDeleteLineItem() {
    setConfirmDialog({ open: false, lineItem: null })

    if (confirmDialog.lineItem) {
      if (tableRef.current?.setLoading) {
        tableRef.current.setLoading(true)
      }

      deleteRecord.execute(
        LineItemApiUrls.DELETE,
        confirmDialog.lineItem,
        () => {
          showNotification('success', 'Item information deleted permanently.')

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => {
          console.log(error)
        },
        () => {
          if (tableRef.current?.setLoading) {
            tableRef.current.setLoading(false)
          }
        }
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'secondary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () =>
                setLineItemDialog({ open: true, lineItem: null, isView: false })
            },
            label: 'Create new line item'
          }
        ]}
        columns={columns}
        compact
        customRowActions={[
          {
            icon: ['fal', 'pen'],
            onClick: (rowData) =>
              setLineItemDialog({
                open: true,
                lineItem: rowData,
                isView: false
              }),
            tooltip: () => translatedTextsObject.editButton
          },
          {
            icon: ['fal', 'trash'],
            iconButtonProps: {
              style: { color: theme.palette.error.main }
            },
            onClick: (rowData) =>
              setConfirmDialog({ open: true, lineItem: rowData }),
            tooltip: () => translatedTextsObject.deleteButton
          }
        ]}
        fetch={{ url: LineItemApiUrls.GET }}
        fetchFilters={[
          { field: 'invoiceId', operator: EQUAL, value: invoiceId }
        ]}
        onRowClick={(rowData) =>
          setLineItemDialog({ open: true, lineItem: rowData, isView: true })
        }
        tableRef={tableRef}
      />
      <LineItemDialog
        getLookupValue={getLookupValue}
        isView={lineItemDialog.isView}
        invoiceId={invoiceId}
        onClose={() =>
          setLineItemDialog({ open: false, lineItem: null, isView: false })
        }
        onCreateLineItem={handleCreateLineItem}
        onEditLineItem={handleEditLineItem}
        open={lineItemDialog.open}
        lineItem={lineItemDialog.lineItem}
        showNotification={showNotification}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, lineItem: null })}
        onCancel={() => setConfirmDialog({ open: false, lineItem: null })}
        onConfirm={handleDeleteLineItem}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default LineItemTable
