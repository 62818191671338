import makeValidationSchema from './LaceyActFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import LaceyActDetailsSection from './LaceyActDetailsSection'

const {
  form: {
    field: { CngDateField, CngTextField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNo: '',
  laceyDate: '',
  printName: '',
  estimatedArrivalDate: '',
  descOfMerchandise: '',
  laceyActDetails: []
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, watch, trigger } = useFormContext()

  const laceyActDetails = watch('laceyActDetails')

  function makeTranslatedTextsObject() {
    let containerNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActForm.CONTAINER_NO
    )
    let laceyDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActForm.LACEY_DATE
    )
    let printName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActForm.PRINT_NAME
    )
    let estimatedArrivalDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActForm.ESTIMATED_ARRIVAL_DATE
    )
    let descOfMerchandise = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActForm.DESC_OF_MERCHANDISE
    )
    let laceyActDetails = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActDetails.TITLE
    )

    return {
      containerNo,
      laceyDate,
      printName,
      estimatedArrivalDate,
      descOfMerchandise,
      laceyActDetails,
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.containerNo}>
            <CngTextField
              name='containerNo'
              label={translatedTextsObject.containerNo}
              disabled={disabled}
              onChange={(e) => setValue('containerNo', e.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.laceyDate}>
            <CngDateField
              name='laceyDate'
              label={translatedTextsObject.laceyDate}
              disabled={disabled}
              size='small'
              onBlur={(event) => trigger('laceyDate')}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.printName}>
            <CngTextField
              name='printName'
              label={translatedTextsObject.printName}
              disabled={disabled}
              onChange={(e) => setValue('printName', e.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={3} shouldHideMap={shouldHideMap?.estimatedArrivalDate}>
            <CngDateField
              name='estimatedArrivalDate'
              label={translatedTextsObject.estimatedArrivalDate}
              disabled={disabled}
              size='small'
              onBlur={(event) => trigger('estimatedArrivalDate')}
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHideMap={shouldHideMap?.descOfMerchandise}>
            <CngTextField
              name='descOfMerchandise'
              label={translatedTextsObject.descOfMerchandise}
              disabled={disabled}
              onChange={(e) => setValue('descOfMerchandise', e.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardHeader
            title={translatedTextsObject.laceyActDetails}
            titleTypographyProps={{ style: { fontWeight: 600 }}}
          />
          <CardContent>
            <LaceyActDetailsSection laceyActDetails={laceyActDetails} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
	)
}

const LaceyActFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default LaceyActFormProperties
