import React, { useEffect,useState } from 'react'
import { components, constants, DataFlattener, DateTimeFormatter, useTranslation, useServices } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../constants/locale/key/SbciInvoice'
import makeValidationSchema from './MakeValidationSchema'
import CiFormFormProperties from './CiFormFormProperties'
import NaUSPortAutocompleteField from '../../../components/na/autocomplete/codemaintenance/NaUSPortAutocompleteField'
import NaUSForeignPortAutocompleteField from '../../../components/na/autocomplete/codemaintenance/NaUSForeignPortAutocompleteField'
import SbciInvoiceAutoCompleteMasterField from './autocomplete/SbciInvoiceAutoCompleteMasterField'
import IdentificationInfoSection from './IdentificationInfoSection'
import AdditionalChargesSection from './AdditionalChargesSection'
import CngSection from '../../../components/cngcomponents/CngSection'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import Switch from '../../../components/Switch'
import CargoSummarySection from './CargoSummarySection'
import AdditionalFormInfoSection from './AdditionalFormInfoSection'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Grid, Card, Typography, Box, Divider, InputAdornment } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TradePartySection from './TradePartySection'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import SbciInvoiceApiUrls from '../../../apiUrls/SbciInvoiceApiUrls'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'
import IdentificationInfoFormProperties from './IdentificationInfoFormProperties'
import AddiInfoFormProperties from '../productmaster/AddiInfoFormProperties'
import AdditionalChargesFormProperties from './AdditionalChargesFormProperties'
import CargoSummaryFormProperties from './CargoSummaryFormProperties'


const {
  form: {
    field: {
      CngCodeMasterAutocompleteField,
      CngCheckboxField,
      CngTextField,
      CngDateField,
    }
  },
  CngGridItem
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  manifestId: '',
  templateNameDropDown: '',
  invoiceNo: '',
  invoiceDate: '',
  invoiceType: 'A',
  entryType: '',
  entryNo: '',
  clientCode: '',
  portLadingCode: '',
  portOfDestination: '',
  header: '',
  footer: '',
  freightCharge: '',
  carOrTrailerNo: '',
  carrierCode: '',
  totalValue: '',
  grossWeight: '',
  netWeight: '',
  description: '',
  ctpInitiative: 'N',
  status: '',
  submissionType: '',
  responseDatetime: '',
  responseId: '',
  remainderFlag: '',
  remainderNote: '',
  shipmentQty: '',
  lockedBy: '',
  lockedDate: '',
  submittedBy: '',
  submittedDate: '',
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  version: '',
  partyId: '',
  templateFlag: false,
  templateName: '',
  tradeParty: [],
  bolForm: null,
  naftaForm: null,
  packingListForm: null,
  additionalCharges: [],
  identificationInfo: [],
  laceyActForm: null,
  ciForm: null,
  tscaForm: null,
  cloneLineItemKey: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap,onSetLoading }) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, trigger, watch,getValues } = useFormContext()
  const { securedSendRequest } = useServices()

  const templateFlag = watch('templateFlag')
  const ctpInitiative = watch('ctpInitiative')
  const identificationInfo = watch('identificationInfo')
  const additionalCharges = watch('additionalCharges')
  const tradeParty = watch('tradeParty')
  const cargoSummary = watch('ciForm.cargoSummary')
  const ciForm = watch('ciForm')
  const invoiceType = watch('invoiceType')
  const entryType = watch('entryType')
  const portLadingCode = watch('portLadingCode')
  const portOfDestination = watch('portOfDestination')

  const [user, setUser] = useState([]);
  watch("fileForUserId");
  watch("fileForUserPartyId");
  watch("fileForUserLoginId");

  useEffect(() => {
    
    let fileForUserDetails = FileForUserGetUserDetails();
    if(fileForUserDetails!=null && fileForUserDetails!=undefined){
      setValue("fileForUserId",fileForUserDetails.fileForUserId)
      setValue("fileForUserPartyId",fileForUserDetails.fileForUserPartyId)
      setValue("fileForUserLoginId",fileForUserDetails.fileForUserLoginId)
    }

    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(()=>{

    let clientCode = ""
    if (user && user.upsSacClientId) {
      Object.entries(user.upsSacClientId).forEach((item) => {
          for (const key in item[1]) {
              if (key == 'upsSacClientId') {
                clientCode = item[1][key];
              }
          }

          let value = getValues("clientCode")
          if ((value == null || value == undefined || value == "")) {
            if(clientCode.indexOf("~") !== -1){
              setValue("clientCode",clientCode.substring(0,clientCode.indexOf("~")));
            }else{
              setValue("clientCode",clientCode)
            }
          }
      })
    }
  },[user]);


  function makeTranslatedTextsObject() {
    const sbciInvoice = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.TITLE)
    const templateNameDropDown = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TEMPLATE_NAME
    )
    const invoiceNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_NO
    )
    const invoiceDate = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_DATE
    )
    const invoiceType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.INVOICE_TYPE
    )
    const entryType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.ENTRY_TYPE
    )
    const entryNo = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.ENTRY_NO)
    const clientCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CLIENT_CODE
    )
    const portLadingCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PORT_LADING_CODE
    )
    const portOfDestination = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PORT_OF_DESTINATION
    )
    const header = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.HEADER)
    const footer = translate(Namespace.SBCI_INVOICE, SbciInvoiceKeys.FOOTER)
    const freightCharge = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.FREIGHT_CHARGE
    )
    const carOrTrailerNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CAR_OR_TRAILER_NO
    )
    const carrierCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CARRIER_CODE
    )
    const totalValue = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TOTAL_VALUE
    )
    const grossWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.GROSS_WEIGHT
    )
    const netWeight = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NET_WEIGHT
    )
    const description = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.DESCRIPTION
    )
    const ctpInitiative = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CTP_INITIATIVE
    )
    const shipmentQty = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.SHIPMENT_QTY
    )
    const additionalCharges = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.AdditionalCharges.TITLE
    )
    const identificationInfo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.IdentificationInfo.TITLE
    )
    const cargoSummary = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.CargoSummary.TITLE
    )
    const templateFlag = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TEMPLATE_FLAG
    )
    const templateName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TEMPLATE_NAME
    )

    return {
      sbciInvoice,
      templateNameDropDown,
      invoiceNo,
      invoiceDate,
      invoiceType,
      clientCode,
      carrierCode,
      carOrTrailerNo,
      entryType,
      entryNo,
      freightCharge,
      totalValue,
      grossWeight,
      netWeight,
      portLadingCode,
      portOfDestination,
      description,
      ctpInitiative,
      shipmentQty,
      header,
      footer,
      identificationInfo,
      additionalCharges,
      cargoSummary,
      templateFlag,
      templateName
    }
  }

  function handleApplyTemplate(template) {
    if (!template) return

    const { data } = template

    const {
      id
    } = data

    let rowData = {
      id: id
    }
    onSetLoading(true)
    securedSendRequest.execute(
      'POST',
      SbciInvoiceApiUrls.CLONE,
      rowData,
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages

        if (response.status == 200 && data != null && errorMessages == null) {
          let record = response.data

          const partyData = record.tradeParty.map(
            (tradeParty, index) => ({ ...tradeParty, _id: index + 1 })
          )

          const additionalChargesData = record.additionalCharges.map(
            (additionalCharges, index) => ({ ...additionalCharges, _id: index + 1 })
          )

          const identificationInfoData = record.identificationInfo.map(
            (identificationInfo, index) => ({ ...identificationInfo, _id: index + 1 })
          )

          let ciFormData = record.ciForm
          if (ciFormData !== undefined || ciFormData !== null) {
            const cargoSummaryData = ciFormData.cargoSummary.map(
              (cargoSummary, index) => ({ ...cargoSummary, _id: index + 1 })
            )
            ciFormData.cargoSummary = cargoSummaryData
          }
          setValue('carOrTrailerNo', record.carOrTrailerNo || '')
          setValue('carrierCode', record.carrierCode || '')
          //setValue('clientCode', record.clientCode || '')
          setValue('ctpInitiative', record.ctpInitiative || 'N')
          setValue('description', record.description || '')
          setValue('entryNo', record.entryNo || '')
          setValue('entryType', record.entryType || '')
          setValue('footer', record.footer || '')
          setValue('freightCharge', record.freightCharge || '')
          setValue('grossWeight', record.grossWeight || '')
          setValue('header', record.header || '')
          setValue('invoiceDate', record.invoiceDate ? DateTimeFormatter.toClientDate(record.invoiceDate) : '')
          setValue('invoiceNo', record.invoiceNo || '')
          setValue('invoiceType', record.invoiceType || '')
          setValue('netWeight', record.netWeight || '')
          setValue('portLadingCode', record.portLadingCode || '')
          setValue('portOfDestination', record.portOfDestination || '')
          setValue('remainderFlag', record.remainderFlag || '')
          setValue('remainderNote', record.remainderNote || '')
          setValue('responseDatetime', record.responseDatetime ? DateTimeFormatter.toClientDate(record.responseDatetime) : '')
          setValue('responseId', record.responseId || '')
          setValue('shipmentQty', record.shipmentQty || '')
          setValue('submissionType', record.submissionType || '')
          setValue('totalValue', record.totalValue || '')
          setValue('tradeParty', partyData || [])
          setValue('bolForm', record.bolForm || null)
          setValue('naftaForm', record.naftaForm || null)
          setValue('packingListForm', record.packingListForm || null)
          setValue('additionalCharges', additionalChargesData || [])
          setValue('identificationInfo', identificationInfoData || [])
          setValue('laceyActForm', record.laceyActForm || null)
          setValue('ciForm', ciFormData || null)
          setValue('tscaForm', record.tscaForm || null)
          setValue('cloneLineItemKey',id)
          trigger()
        } else {
          showNotification('error', errorMessages)
        }
        onSetLoading(false)
      },
      (error) => console.error(error)
    )
   
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box padding={1}>
            <Grid alignItems='stretch' container spacing={1}>
              <Grid item xs={12} md={4}>
                <SbciInvoiceAutoCompleteMasterField
                  name='templateNameDropDown'
                  label='Auto-fill using template'
                  disabled={disabled}
                  onChange={(_, options) => handleApplyTemplate(options)}
                  size='small'
                  fullWidth
                  disableClearable
                  textFieldProps={{
                    InputProps: {
                      customEndAdornment: () => (
                        <InputAdornment position='end' style={{ marginTop: -16 }}>
                          <Box display='flex' alignItems='center' justifyContent='center' width={48} height={48}>
                            <FontAwesomeIcon icon={['fal', 'money-check-edit']} />
                          </Box>
                        </InputAdornment>
                      )
                    }
                  }}
                  forcePopupIcon={false}
                  lookupProps={{
                    filters: [{
                      field: 'partyId',
                      operator: EQUAL,
                      value: FileForUserGetPartyId()
                    }]
                  }}
                />
              </Grid>
              <Grid item xs='auto'>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs={12} md>
                <Grid alignItems='center' container spacing={1}>
                  <CngGridItem xs={12} sm='auto' shouldHide={shouldHideMap?.templateFlag}>
                    <CngCheckboxField
                      label={
                        <Typography style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {translatedTextsObject.templateFlag}
                        </Typography>
                      }
                      name='templateFlag'
                      disabled={disabled}
                      onChange={(event) =>
                        setValue('templateFlag', event.target.checked)
                      }
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                      value={!templateFlag ? false : true}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm shouldHide={shouldHideMap?.templateName}>
                    <CngTextField
                      name='templateName'
                      label={translatedTextsObject.templateName}
                      disabled={!templateFlag}
                      size='small'
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={translatedTextsObject.sbciInvoice}
          subheader={
            <Typography color='textSecondary' variant='caption'>
              <Typography variant='inherit' color='error'>
                *
              </Typography>
              Mandatory fields
            </Typography>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.invoiceNo}>
                  <CngTextField
                    name='invoiceNo'
                    label={translatedTextsObject.invoiceNo}
                    disabled={disabled}
                    onChange={(event) =>
                      setValue('invoiceNo', event.target.value.toUpperCase(), {shouldValidate:true})
                    }
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.invoiceDate}>
                  <CngDateField
                    name='invoiceDate'
                    label={translatedTextsObject.invoiceDate}
                    disabled={disabled}
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.invoiceType}>
                  <CngCodeMasterAutocompleteField
                    codeType='SBCI_INVOICE_TYPE'
                    name='invoiceType'
                    key={invoiceType}
                    label={translatedTextsObject.invoiceType}
                    disabled={disabled}
                    required
                    size='small'
                    onChange={(_, option) =>
                      setValue('invoiceType', option ? option.value : '', {shouldValidate:true})
                    }
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.clientCode}>
                  <CngTextField
                    name='clientCode'
                    label={translatedTextsObject.clientCode}
                    disabled={true}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.carrierCode}>
                  <CngTextField
                    name='carrierCode'
                    label={translatedTextsObject.carrierCode}
                    onChange={(event) =>
                      setValue('carrierCode', event.target.value.toUpperCase(), {shouldValidate:true})
                    }
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.carOrTrailerNo}>
                  <CngTextField
                    name='carOrTrailerNo'
                    label={translatedTextsObject.carOrTrailerNo}
                    onChange={(event) =>
                      setValue('carOrTrailerNo', event.target.value.toUpperCase(), {shouldValidate:true})
                    }
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.entryType}>
                  <CngCodeMasterAutocompleteField
                    codeType='SBCI_ENTRY_TYPE'
                    name='entryType'
                    key={entryType}
                    label={translatedTextsObject.entryType}
                    disabled={disabled}
                    required
                    size='small'
                    onChange={(_, option) =>
                      setValue('entryType', option ? option.value : '', {shouldValidate:true})
                    }
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.entryNo}>
                  <CngTextField
                    name='entryNo'
                    label={translatedTextsObject.entryNo}
                    onChange={(event) =>
                      setValue('entryNo', event.target.value.toUpperCase(), {shouldValidate:true})
                    }
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.freightCharge}>
                  <CngTextField
                    name='freightCharge'
                    label={translatedTextsObject.freightCharge}
                    required
                    size='small'
                    onChange={(event) => trigger('freightCharge')}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.totalValue}>
                  <CngTextField
                    name='totalValue'
                    label={translatedTextsObject.totalValue}
                    required
                    size='small'
                    onChange={(event) => trigger('totalValue')}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.grossWeight}>
                  <CngTextField
                    name='grossWeight'
                    label={translatedTextsObject.grossWeight}
                    required
                    size='small'
                    onChange={(event) => trigger('grossWeight')}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.netWeight}>
                  <CngTextField
                    name='netWeight'
                    label={translatedTextsObject.netWeight}
                    required
                    size='small'
                    onChange={(event) => trigger('netWeight')}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.portLadingCode}>
                  <NaUSForeignPortAutocompleteField
                    name='portLadingCode'
                    key={portLadingCode}
                    label={translatedTextsObject.portLadingCode}
                    disabled={disabled}
                    required
                    size='small'
                    onChange={(_, option) =>
                      setValue('portLadingCode', option ? option.value : '', {shouldValidate:true})
                    }
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.portOfDestination}>
                  <NaUSPortAutocompleteField
                    name='portOfDestination'
                    key={portOfDestination}
                    label={translatedTextsObject.portOfDestination}
                    disabled={disabled}
                    required
                    size='small'
                    onChange={(_, option) =>
                      setValue('portOfDestination', option ? option.value : '', {shouldValidate:true})
                    }
                  />
                </CngGridItem>
                <CngGridItem xs={12} lg={6} shouldHide={shouldHideMap.description}>
                  <CngTextField
                    name='description'
                    label={translatedTextsObject.description}
                    onChange={(event) =>
                      setValue('description', event.target.value.toUpperCase(), {shouldValidate:true})
                    }
                    required
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.ctpInitiative}>
                  <Switch
                    fullWidth
                    name='ctpInitiative'
                    checked={ctpInitiative === 'Y'}
                    label={translatedTextsObject.ctpInitiative}
                    disabled={disabled}
                    onChange={(event) => setValue('ctpInitiative', event.target.checked ? 'Y' : 'N')}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.shipmentQty}>
                  <CngTextField
                    name='shipmentQty'
                    label={translatedTextsObject.shipmentQty}
                    size='small'
                    onChange={(event) => trigger('shipmentQty')}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.header}>
                  <CngTextField
                    name='header'
                    label={translatedTextsObject.header}
                    onChange={(event) =>
                      setValue('header', event.target.value.toUpperCase(), {shouldValidate:true})
                    }
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap.footer}>
                  <CngTextField
                    name='footer'
                    label={translatedTextsObject.footer}
                    onChange={(event) =>
                      setValue('footer', event.target.value.toUpperCase(), {shouldValidate:true})
                    }
                    size='small'
                  />
                </CngGridItem>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CollapsibleSection
                defaultExpanded={!_.isEmpty(identificationInfo)}
                onExpandedChange={(expanded) => {
                  const { initialValues } = IdentificationInfoFormProperties.formikProps
                  setValue('identificationInfo', expanded ? [{ ...initialValues, _id: 1 }] : [])
                }}
                title={translatedTextsObject.identificationInfo}
              >
                <IdentificationInfoSection identificationInfo={identificationInfo} />
              </CollapsibleSection>
            </Grid>
            <Grid item xs={12}>
              <CollapsibleSection
                defaultExpanded={!_.isEmpty(additionalCharges)}
                onExpandedChange={(expanded) => {
                  const { initialValues } = AdditionalChargesFormProperties.formikProps
                  setValue('additionalCharges', expanded ? [{ ...initialValues, _id: 1 }] : [])
                }}
                title={translatedTextsObject.additionalCharges}
              >
                <AdditionalChargesSection
                  additionalCharges={additionalCharges}
                />
              </CollapsibleSection>
            </Grid>
            <Grid item xs={12}>
              <TradePartySection tradeParty={tradeParty} />
            </Grid>
            <Grid item xs={12}>
              <CollapsibleSection
                defaultExpanded={!!ciForm}
                onExpandedChange={(expanded) => {
                  if (expanded && !ciForm) {
                    setValue('ciForm', CiFormFormProperties.formikProps.initialValues)
                  }
                }}
                title='Additional Information'
              >
                {ciForm && (
                  <CiFormFormProperties.Fields />
                )}
              </CollapsibleSection>
            </Grid>
            <Grid item xs={12}>
              <CollapsibleSection
                defaultExpanded={!_.isEmpty(cargoSummary)}
                onExpandedChange={(expanded) => {
                  const { initialValues } = CargoSummaryFormProperties.formikProps
                  setValue('ciForm.cargoSummary', expanded ? [{ ...initialValues, _id: 1 }] : [])
                }}
                title={translatedTextsObject.cargoSummary}
              >
                <CargoSummarySection cargoSummary={cargoSummary} />
              </CollapsibleSection>
            </Grid>
            <Grid item xs={12}>
              <AdditionalFormInfoSection showNotification={showNotification} />
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData.invoiceDate = DateTimeFormatter.toClientDate(localData.invoiceDate)
  localData.responseDatetime = DateTimeFormatter.toClientDate(
    localData.responseDatetime
  )
  localData.lockedDate = DateTimeFormatter.toClientDate(localData.lockedDate)
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  )
  localData.createdDate = DateTimeFormatter.toClientDate(localData.createdDate)
  localData.updatedDate = DateTimeFormatter.toClientDate(localData.updatedDate)

  if (!_.isEmpty(localData.naftaForm)) {
    const { dateFrom, dateTo, naftaDate } = localData.naftaForm

    localData.naftaForm.dateFrom = DateTimeFormatter.toClientDate(dateFrom)
    localData.naftaForm.dateTo = DateTimeFormatter.toClientDate(dateTo)
    localData.naftaForm.naftaDate = DateTimeFormatter.toClientDate(naftaDate)
  }

  if (!_.isEmpty(localData.packingListForm)) {
    localData.packingListForm.dateShipped = DateTimeFormatter.toClientDate(
      localData.packingListForm.dateShipped
    )
  }

  if (!_.isEmpty(localData.laceyActForm)) {
    const { laceyDate, estimatedArrivalDate } = localData.laceyActForm

    localData.laceyActForm.laceyDate = DateTimeFormatter.toClientDate(laceyDate)
    localData.laceyActForm.estimatedArrivalDate =
      DateTimeFormatter.toClientDate(estimatedArrivalDate)
  }

  if (!_.isEmpty(localData.ciForm)) {
    const { shipmentDate, arrivalDatetime } = localData.ciForm

    localData.ciForm.shipmentDate = DateTimeFormatter.toClientDate(shipmentDate)
    localData.ciForm.arrivalDatetime =
      DateTimeFormatter.toClientDate(arrivalDatetime)
  }

  if (!_.isEmpty(localData.tscaForm)) {
    const { tscaDate, researchCert } = localData.tscaForm

    localData.tscaForm.tscaDate = DateTimeFormatter.toClientDate(tscaDate)
    localData.tscaForm.researchCert = researchCert === 'CH3' ? 'Y' : 'N'
  }

  return localData
}

function toServerDataFormat(localData) {
  let serverData = { ...localData }

  serverData.invoiceDate = DateTimeFormatter.toServerDate(
    serverData.invoiceDate
  )
  serverData.responseDatetime = DateTimeFormatter.toServerDate(
    serverData.responseDatetime
  )
  serverData.lockedDate = DateTimeFormatter.toServerDate(serverData.lockedDate)
  serverData.submittedDate = DateTimeFormatter.toServerDate(
    serverData.submittedDate
  )
  serverData.createdDate = DateTimeFormatter.toServerDate(
    serverData.createdDate
  )
  serverData.updatedDate = DateTimeFormatter.toServerDate(
    serverData.updatedDate
  )
  serverData.ctpInitiative = serverData.ctpInitiative ? 'Y' : 'N'

  if (!_.isEmpty(serverData.naftaForm)) {
    const { dateFrom, dateTo, naftaDate } = serverData.naftaForm

    serverData.naftaForm.dateFrom = DateTimeFormatter.toServerDate(dateFrom)
    serverData.naftaForm.dateTo = DateTimeFormatter.toServerDate(dateTo)
    serverData.naftaForm.naftaDate = DateTimeFormatter.toServerDate(naftaDate)
  }

  if (!_.isEmpty(serverData.packingListForm)) {
    let { containerized, dateShipped } = serverData.packingListForm

    containerized = containerized ? 'Y' : 'N'
    dateShipped = DateTimeFormatter.toServerDate(dateShipped)
  }

  if (!_.isEmpty(serverData.laceyActForm)) {
    const { laceyDate, estimatedArrivalDate } = serverData.laceyActForm

    serverData.laceyActForm.laceyDate =
      DateTimeFormatter.toServerDate(laceyDate)
    serverData.laceyActForm.estimatedArrivalDate =
      DateTimeFormatter.toServerDate(estimatedArrivalDate)
  }

  if (!_.isEmpty(serverData.ciForm)) {
    const { shipmentDate, arrivalDatetime } = serverData.ciForm

    serverData.ciForm.shipmentDate =
      DateTimeFormatter.toServerDate(shipmentDate)
    serverData.ciForm.arrivalDatetime =
      DateTimeFormatter.toServerDate(arrivalDatetime)
  }

  if (!_.isEmpty(serverData.tscaForm)) {
    serverData.tscaForm.tscaDate = DateTimeFormatter.toServerDate(
      serverData.tscaForm.tscaDate
    )
    serverData.tscaForm.researchCert =
      serverData.tscaForm.researchCert === 'Y' ? 'CH3' : 'N'
  }

  return DataFlattener.unflatten(serverData)
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
