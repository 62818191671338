import React, { useState } from 'react'
import { useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import SbciInvoiceKeys from '../../../../constants/locale/key/SbciInvoice'
import SbciInvoiceApiUrls from '../../../../apiUrls/SbciInvoiceApiUrls'
import CngSection from '../../../../components/cngcomponents/CngSection'
import { Grid } from '@material-ui/core'
import { format } from 'date-fns'
import QuickExportButton from '../QuickExportButton'

function AdditionalFormInfoSection(props) {
  const { invoiceData, showNotification } = props

  const [download, setDownload] = useState({ form: null, loading: false })
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let bolForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.BolForm.TITLE
    )
    let tscaForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TscaForm.TITLE
    )
    let packingListForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.PackingListForm.TITLE
    )
    let laceyActForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.LaceyActForm.TITLE
    )

    let naftaForm = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.NaftaForm.TITLE
    )

    return {
      bolForm,
      tscaForm,
      packingListForm,
      laceyActForm,
      naftaForm
    }
  }

  async function handleDownload({ formKey, url, prefix }) {
    try {
      setDownload({ form: formKey, loading: true })
      const config = { responseType: 'blob' } 

      await securedSendRequest.execute(
        'POST',
        url,
        invoiceData,
        (response) => {
          const blob = new Blob([response.data])
          const curDate = format(new Date(), 'yyyyMMddHHmmss')
          const a = document.createElement('a')

          a.href = window.URL.createObjectURL(blob)
          a.download = `${prefix}_${curDate}.pdf`
          a.click()
        },
        (error) => {
          console.error(error)
          showNotification('error', 'Download failed')
        },
        undefined,
        config,
        null
      )
    } catch (error) {
      console.log(error)
    } finally {
      setDownload({ form: null, loading: false })
    }
  }

  return (
    <CngSection title='Additional Form Information'>
      <Grid container spacing={2}>
        <Grid item xs='auto'>
          <QuickExportButton
            disabled={download.loading}
            loading={download.loading && download.form === 'bol'}
            label={translatedTextsObject.bolForm}
            onClick={() =>
              handleDownload({
                formKey: 'bol',
                url: SbciInvoiceApiUrls.PRINT_BOL,
                prefix: 'SBCI_BOL'
              })
            }
          />
        </Grid>
        <Grid item xs='auto'>
          <QuickExportButton
            disabled={download.loading}
            loading={download.loading && download.form === 'tsca'}
            label={translatedTextsObject.tscaForm}
            onClick={() =>
              handleDownload({
                formKey: 'tsca',
                url: SbciInvoiceApiUrls.PRINT_TSCA,
                prefix: 'SBCI_TSCA'
              })
            }
          />
        </Grid>
        <Grid item xs='auto'>
            <QuickExportButton
              disabled={download.loading}
              loading={download.loading && download.form === 'naftaForm'}
              label={translatedTextsObject.naftaForm}
              onClick={() =>
                handleDownload({
                  formKey: 'nafta',
                  url: SbciInvoiceApiUrls.PRINT_USMCA_CUSMA,
                  prefix: 'SBCI_USMCA'
                })
              }
            />
          </Grid>
        <Grid item xs='auto'>
          <QuickExportButton
            disabled={download.loading}
            loading={download.loading && download.form === 'packingList'}
            label={translatedTextsObject.packingListForm}
            onClick={() =>
              handleDownload({
                formKey: 'packingList',
                url: SbciInvoiceApiUrls.PRINT_PL,
                prefix: 'SBCI_PL_'
              })
            }
          />
        </Grid>
        <Grid item xs='auto'>
          <QuickExportButton
            disabled={download.loading}
            loading={download.loading && download.form === 'laceyAct'}
            label={translatedTextsObject.laceyActForm}
            onClick={() =>
              handleDownload({
                formKey: 'laceyAct',
                url: SbciInvoiceApiUrls.PRINT_LACEYACT,
                prefix: 'SBCI_LACEY_ACT'
              })
            }
          />
        </Grid>
      </Grid>
    </CngSection>
  )
}

export default AdditionalFormInfoSection
